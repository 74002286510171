import type { ZudokuConfig } from "zudoku";

const config: ZudokuConfig = {
  plugins: [{
    getHead: () => {
      return <style>
        {`header span { white-space: nowrap; }`}
      </style>;
    }
  }],
  page: {
    pageTitle: "SecuriThings Developer Center",
    logo: {
      src: {
        light: "/favicon.png",
        dark: "/favicon.png"
      }
    }
  },
  topNavigation: [
    { id: "documentation", label: "Documentation" },
    { id: "api", label: "API Reference" }
  ],
  metadata: {
    title: "SecuriThings API Documentation",
    favicon: "/favicon.png"
  },
  theme: {
    light: {
      primary: "194, 85%, 61%", // R
      primaryForeground: "194, 85%, 0%" // B
    },
    dark: {
      primary: "120, 100%, 50%", // G
      primaryForeground: "60, 100%, 50%" // Y
    }
  },
  sidebar: {
    documentation: [
      {
        type: "doc",
        label: "Introduction",
        id: "introduction"
      },
      {
        type: "category",
        label: "Quick Start",
        items: ["guides/authentication", "guides/filtering-and-pagination", "guides/responses-and-errors", "guides/rate-limits-and-throttling"],
        collapsed: false
      },
      {
        type: "category",
        label: "How-Tos",
        items: ["how-to/fetch-offline-devices", "how-to/fetch-all-vulnerable-devices", "how-to/find-devices-to-renew"],
        collapsed: false
      },
      {
        type: "category",
        label: "News And Updates",
        items: ["changelog", "news"],
        collapsed: false
      }
    ]
  },
  redirects: [
    { from: "/", to: "/introduction" }
  ],
  apis: [
    {
      type: "file",
      input: "api-specs/openapi/root.yaml",
      navigationId: "api"
    }
  ],
  docs: {
    files: "/pages/**/*.{md,mdx}"
  }
};

export default config;
